import Vue, { provide, watch, h } from 'vue';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';

// import '@/registerServiceWorker';
import * as filters from '@/lib/filters';
import { pageHeader } from '@/components/pages';
import '@/components/form/index';
import '@/lib/imp';
import errorHandler from '@/components/errorHandler';
import { uv } from '@/components/uv';
import { permissions } from '@/components/permissions';
import { plugin as VuetifyConfirm, makeConfirmDialog } from '@/plugins/vuetify-confirm';
import { plugin as download, makeDownload } from '@/plugins/download';
import { plugin as VeeValidate } from '@/plugins/vee-validate';
import '@/assets/style.scss';
import { VProgressCircular } from 'vuetify/lib';
import {
  BooleanFormatterKey,
  ConfirmDialogKey,
  CurrencyFormatterKey,
  DateFormatterAltKey,
  DateFormatterKey,
  DateParserKey,
  DateTimeFormatterKey,
  DurationHumanizerKey,
  TimestampFormatterKey,
  MsgKey,
  NullableFormatterKey,
  PageDepsKey,
  QuantityFormatterKey,
  RouterKey,
  StoreKey,
  TruncateFormatterKey,
  DownloadKey,
} from '@/symbols';
import { Plugin } from 'vue-fragment';

export const bus = new Vue();

const confirmOptions = {
  buttonTrueText: 'Confirma',
  buttonFalseText: 'Anuleaza',
  title: '',
  vuetify,
};

Vue.use(VuetifyConfirm, confirmOptions);
Vue.use(VeeValidate);
Vue.use(download);
Vue.use(errorHandler);
Vue.use(pageHeader);
Vue.use(uv);
Vue.use(permissions);
Vue.use(Plugin);

Vue.config.productionTip = false;

const loading = store.dispatch('auth/initialize').then(() => {
  watch(
    () => store.state.auth.token,
    (current, prev) => {
      console.log({ current, prev });
      if (typeof current === 'string' && !prev) {
        if (router.currentRoute.name === 'login') {
          console.log('Redirecting from /login to /home');
          router.push({
            name: 'home',
          });
        }
      } else if (!current && !prev) {
        console.log('Redirecting to /login as no tokens are found');
        router.push({ name: 'login' });
      } else if (!current) {
        console.log('Redirecting to /login as no current token is found');
        window.location.href = '/login';
      }
    },
    { immediate: true },
  );
});

const msg: MessageLogger = (_msg: unknown) => store.dispatch('messages/push', _msg);

router.beforeEach(async (to, from, next) => {
  await loading;

  const hasPermission =
    to.matched
      .filter((route) => route.meta.permissions)
      .flatMap((route) => route.meta.permissions)
      .filter((permission: string) => {
        const [action, subject] = permission.split('.');
        return !store.state.auth.ability!.can(action, subject);
      }).length === 0;

  if (hasPermission) {
    return next();
  }

  console.log('Redirecting to /login as the user has no permissions');
  next({ name: 'login' });

  msg(new Error('Unauthorized.'));
});

router.onError(msg);

const pageDeps: PageDeps = (dep: string | symbol, value: any) => store.commit('pageDeps/set', { dep, value });

store.dispatch('system/start');

const confirmDialog = makeConfirmDialog(Vue, confirmOptions);

new Vue({
  name: 'App',
  router,
  store,
  vuetify,
  provide: {
    ...filters,
    confirm: confirmDialog,
    store,
    router,
    pageDeps,
    msg,
  },
  setup() {
    provide(ConfirmDialogKey, confirmDialog);
    provide(MsgKey, msg);
    provide(RouterKey, router);
    provide(PageDepsKey, pageDeps);
    provide(StoreKey, store);
    provide(DateFormatterKey, filters.formatDate);
    provide(DateFormatterAltKey, filters.formatDateAlt);
    provide(DateTimeFormatterKey, filters.formatDateTime);
    provide(TimestampFormatterKey, filters.formatTimestamp);
    provide(DurationHumanizerKey, filters.humanizeDuration);
    provide(DateParserKey, filters.humanizeDuration);
    provide(QuantityFormatterKey, filters.formatQuantity);
    provide(CurrencyFormatterKey, filters.formatCurrency);
    provide(BooleanFormatterKey, filters.boolean);
    provide(NullableFormatterKey, filters.nullable);
    provide(TruncateFormatterKey, filters.truncate);
    provide(DownloadKey, makeDownload);

    return () => {
      return store.state.auth.initializing
        ? h(
            'div',
            {
              style: { margin: '3em', textAlign: 'center' },
            },
            [
              h(VProgressCircular, {
                props: {
                  size: 200,
                  width: 5,
                  color: 'primary',
                  indeterminate: '',
                },
              }),
            ],
          )
        : h('router-view');
    };
  },
}).$mount('#app');
