<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      data-cy="sidebar"
      fixed
      width="300"
    >
      <v-list class="pa-0">
        <v-list-item v-if="user">
          <v-list-item-avatar color="red">
            <span
              @click="drawer = !drawer"
              v-text="userInitials"
              class="white--text text-h5"
              data-cy="user-initials"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ institution ? institution.name : '' }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch
              v-model="darkTheme"
              dense
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tema intunecata</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <page-sidebar />
      <v-divider />
      <uv-limits />
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-left="clipped-left"
      dense
      fixed
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        data-cy="toggle-sidebar"
      />
      <v-toolbar-title class="d-flex">
        <img
          v-if="darkTheme"
          src="@/assets/logo-dark.png"
        />
        <img
          v-else
          src="@/assets/logo.png"
        />
      </v-toolbar-title>
      <bread-crumbs :items="breadcrumbs" />
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          v-if="applications.includes('gis')"
          :to="{ name: 'me.notifications' }"
          :color="notificationsCount > 0 ? 'primary' : 'default'"
          :loading="notificationsLoading"
          exact
          icon
          text
        >
          <v-badge
            color="red"
            :value="notificationsCount"
            dot
            overlap
          >
            <v-icon>notifications</v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          v-if="applications.includes('inventory')"
          :href="mobileAppURL"
          target="_blank"
          color="primary"
          data-cy="mobile-app-btn"
          icon
          text
        >
          <v-icon>app_shortcut</v-icon>
        </v-btn>
        <v-btn
          @click="logout"
          color="error darken-1"
          data-cy="logout-btn"
          icon
          text
        >
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container
        :fluid="wide"
        :class="{ 'uv-container': true, 'ma-0 pa-0': spaceless }"
        data-cy="page-content"
      >
        <page-header
          v-if="headerItems.length > 0"
          :items="headerItems"
        />
        <uv-alert-icon
          v-if="state.notificationsError"
          type="error"
        >
          {{ state.notificationsError }}
        </uv-alert-icon>
        <router-view />
      </v-container>
    </v-main>
    <messages-list store="messages" />
  </v-app>
</template>
<script lang="ts">
import { mapActions, mapGetters, mapState } from 'vuex';
import MessagesList from '@/components/MessagesList.vue';
import BreadCrumbs from '@/components/pages/breadcrumbs/Main.vue';
import PageHeader from '@/components/pages/header/Main.vue';
import PageSidebar from '@/layout/Sidebar';
import { userFormatter } from '@/lib/formatters';
import config from '@/config';
import { defineComponent, reactive } from 'vue';
import {
  activateNotifications,
  IgnorableFirebaseError,
  NotificationApiNotSupported,
  NotificationsNotSupportedOnSafariBrowser,
  PermissionNotGrantedError,
} from '@/notifications';
import api from '@/api';

interface State {
  notificationsError: null | string;
}

export default defineComponent({
  components: {
    MessagesList,
    BreadCrumbs,
    PageSidebar,
    PageHeader,
  },
  beforeRouteUpdate(to, from, next) {
    if (this.drawer) {
      this.drawer = false;
    }
    next();
  },
  setup() {
    const state = reactive<State>({
      notificationsError: null,
    });

    activateNotifications()
      .then((token) => {
        api.me.notifications.registerToken({ params: { token } });
      })
      .catch((err) => {
        if (err instanceof PermissionNotGrantedError) {
          state.notificationsError =
            'Pentru a putea primi notificari de sistem trebuie sa deblocati notificarile de browser';
        } else if (err instanceof NotificationApiNotSupported) {
          console.log(err);
        } else if (err instanceof NotificationsNotSupportedOnSafariBrowser) {
          console.log(err);
        } else if (err instanceof IgnorableFirebaseError) {
          console.log(err);
        } else {
          state.notificationsError = err.message;
        }
      });
    return {
      state,
    };
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState({
      user: (s: any) => s.auth.user,
      applications: (s: any) => s.auth.applications.map((app: any) => app.id),
      permissions: (s: any) => s.auth.permissions,
      institution: (s: any) => s.userInstitution.institution,
      notificationsCount: (s: any) => s.notifications.count,
      notificationsLoading: (s: any) => s.notifications.loading,
      notificationsError: (s: any) => s.notifications.error,
    }),
    ...mapGetters('pageDeps', ['deps']),
    darkTheme: {
      get() {
        return this.$store.state.system.darkTheme;
      },
      set(v: boolean) {
        this.$store.dispatch('system/updateDarkTheme', !!v);
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    spaceless() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.matched.some(({ meta }) => {
        if ('spaceless' in meta) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return meta.spaceless instanceof Function ? meta.spaceless(this.deps) : !!meta.spaceless;
        }
        return false;
      });
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    wide() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.matched.some(({ meta }) => {
        if ('wide' in meta) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return meta.wide instanceof Function ? meta.wide(this.deps) : !!meta.wide;
        }
        return false;
      });
    },
    headerItems() {
      return (
        this.$route.matched
          .filter((route) => route.meta.hasOwnProperty('header'))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map(({ name, params, meta }) => ({
            name,
            params,
            meta: meta.header,
          }))
          .reverse()
      );
    },
    breadcrumbs() {
      return this.$route.matched.filter((route) => route.meta.hasOwnProperty('breadcrumb'));
    },
    userInitials() {
      return userFormatter.initials(this.user);
    },
    mobileAppURL() {
      return config.mobileAppHost;
    },
  },
  watch: {
    notificationsError(e) {
      if (e) {
        this.$msg(e);
      }
    },
    darkTheme: {
      immediate: true,
      handler(v) {
        this.$vuetify.theme.dark = !!v;
      },
    },
  },
  mounted() {
    this.loadInstitution().catch((e) => this.$msg(e));
    this.$store.dispatch('notifications/update');
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      loadInstitution: 'userInstitution/load',
    }),
  },
});
</script>
<style>
.uv-container {
  height: 100%;
  min-height: 100%;
}
</style>
